<template>
    <div>
      <span class="question">
        5. Which of these statistics accurately describes the current situation in cancer services?<sup>1,2</sup>
      </span>
      <span class="guidance">Select all that apply</span>
      <span class="anwser">
        <label>
          <input v-model="a5" type="checkbox">
          The number of cancer referrals has increased by 50% between 2010 and 2021
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="b5" type="checkbox">
          The number of patients seen following an urgent suspected cancer referral has remained at a record high since March 2022
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="c5" type="checkbox">
          At least 3,600 more patients would have been expected to have come forward to start treatment during the pandemic than have done so
        </label>
      </span>
      <span class="anwser">
        <label>
          <input  v-model="d5" type="checkbox">
          The target for 93% of patients to be seen by a specialist consultant after urgent GP referral has not been met since May 2020
        </label>
      </span>
      <span class="anwser">
        <label>
          <input  v-model="e5" type="checkbox">
          Lower GI, prostate and skin cancers make up two-thirds of the national backlog
        </label>
      </span>
      <span class="notes">
        mpMRI=multiparametric magnetic resonance imaging. All references were last accessed in August 2022.
        1. NHS England. Clinically-led review of NHS cancer standards: models of care and measurement. March 2022. Available from:
        <a href="https://www.england.nhs.uk/publication/clinically-led-review-of-nhs-cancer-standards-models-of-care-and-measurement/;">
          https://www.england.nhs.uk/publication/clinically-led-review-of-nhs-cancer-standards-models-of-care-and-measurement/;
        </a>
        2. NHS England. 2022-23 Priorities and Operational Planning Guidance. February 2022. Available from:
        <a href="https://www.england.nhs.uk/publication/2022-23-priorities-and-operational-planning-guidance/">
          https://www.england.nhs.uk/publication/2022-23-priorities-and-operational-planning-guidance/.
        </a>
      </span>
      <hr />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModuleTwoQuizQ5',
    data() {
      return {
        a5: false,
        b5: false,
        c5: false,
        d5: false,
        e5: false,
      };
    },
    watch: {
      a5(value) { this.$store.commit('answerM2A5', value) },
      b5(value) { this.$store.commit('answerM2B5', value) },
      c5(value) { this.$store.commit('answerM2C5', value) },
      d5(value) { this.$store.commit('answerM2D5', value) },
      e5(value) { this.$store.commit('answerM2E5', value) },
    },
  };
  </script>
  