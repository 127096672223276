<template>
    <div>
      <span class="question">
        1. Which of the following statements about integrated care systems (ICSs) is incorrect?<sup>1,2</sup>
      </span>
      <span class="anwser">
        <label>
          <input v-model="a1" type="checkbox">
          ICSs are partnerships of organisations that come together to plan and deliver joined up health and care services
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="b1" type="checkbox">
          ICSs can enhance productivity and value for money, as well as supporting broader social and economic development
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="c1" type="checkbox">
          ICSs will be established across England on a statutory basis in September 2022
        </label>
      </span>
      <span class="anwser">
        <label>
          <input  v-model="d1" type="checkbox">
          After the establishment of ICSs, Clinical Commissioning Groups no longer play a role in NHS planning
        </label>
      </span>
      <span class="notes">
        1. NHS England. What are integrated care systems? Available from:
        <a href="https://www.england.nhs.uk/integratedcare/what-is-integrated-care/">
            https://www.england.nhs.uk/integratedcare/what-is-integrated-care/
        </a>
        [Last accessed: August 2022];
        <a href="https://www.kingsfund.org.uk/audio-video/integrated-care-systems-health-and-care-act">
            https://www.kingsfund.org.uk/audio-video/integrated-care-systems-health-and-care-act
        </a>
        2. Kings Fund. Integrated care systems: how will they work under the Health and Care Act? May 2022. Available from:
        [Last accessed: August 2022].
      </span>
      <hr />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModuleTwoQuizQ1',
    data() {
      return {
        a1: false,
        b1: false,
        c1: false,
        d1: false,
      };
    },
    watch: {
      a1(value) { this.$store.commit('answerM2A1', value) },
      b1(value) { this.$store.commit('answerM2B1', value) },
      c1(value) { this.$store.commit('answerM2C1', value) },
      d1(value) { this.$store.commit('answerM2D1', value) },
    },
  };
  </script>
  