<template>
    <div>
      <span class="question">
        3. Which of the following are ways that the NHS is investing in its workforce to allow it to grow after the pandemic<sup>1</sup>
      </span>
      <span class="guidance">Select all that apply</span>
      <span class="anwser">
        <label>
          <input v-model="a3" type="checkbox">
          Incentivising performance, including offering bonuses for high patient turnover
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="b3" type="checkbox">
          Improving belonging in the NHS, including improving the minority ethnic disparity ratio 
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="c3" type="checkbox">
          Ensuring the retention of staff by providing mental health training
        </label>
      </span>
      <span class="anwser">
        <label>
          <input  v-model="d3" type="checkbox">
          Growing for the future, including ensuring training of post graduate doctors continues
        </label>
      </span>
      <span class="notes">
        1. NHS England. 2022-23 Priorities and Operational Planning Guidance. February 2022. Available from:
        <a href="https://www.england.nhs.uk/publication/2022-23-priorities-and-operational-planning-guidance/">
          https://www.england.nhs.uk/publication/2022-23-priorities-and-operational-planning-guidance/
        </a>
        [Last accessed: August 2022].
      </span>
      <hr />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModuleTwoQuizQ3',
    data() {
      return {
        a3: false,
        b3: false,
        c3: false,
        d3: false,
      };
    },
    watch: {
      a3(value) { this.$store.commit('answerM2A3', value) },
      b3(value) { this.$store.commit('answerM2B3', value) },
      c3(value) { this.$store.commit('answerM2C3', value) },
      d3(value) { this.$store.commit('answerM2D3', value) },
    },
  };
  </script>
  