<template>
    <div>
      <span class="question">
        4. Which of these statistics accurately reflects the size of the elective backlog?
      </span>
      <span class="anwser">
        <label>
          <input v-model="a4" type="checkbox">
          3 million more people are on the waiting list for elective treatment than before the pandemic
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="b4" type="checkbox">
          7 times more people are waiting more than one year for elective treatment than before the pandemic
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="c4" type="checkbox">
          1 million people have been waiting for more than 18 weeks for elective treatment
        </label>
      </span>
      <span class="anwser">
        <label>
          <input  v-model="d4" type="checkbox">
          The 'hidden backlog' could increase the length of the waiting list to 10 million patients
        </label>
      </span>
      <span class="notes">
        1. British Medical Association. NHS backlog data analysis. July 2022. Available from:
        <a href="https://www.bma.org.uk/advice-and-support/nhs-delivery-and-workforce/pressures/nhs-backlog-data-analysis">
          https://www.bma.org.uk/advice-and-support/nhs-delivery-and-workforce/pressures/nhs-backlog-data-analysis
        </a>
        [Last accessed: August 2022].
      </span>
      <hr />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModuleTwoQuizQ4',
    data() {
      return {
        a4: false,
        b4: false,
        c4: false,
        d4: false,
      };
    },
    watch: {
      a4(value) { this.$store.commit('answerM2A4', value) },
      b4(value) { this.$store.commit('answerM2B4', value) },
      c4(value) { this.$store.commit('answerM2C4', value) },
      d4(value) { this.$store.commit('answerM2D4', value) },
    },
  };
  </script>
  