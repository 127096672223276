<template>
    <div>
      <span class="question">
        6. Which of these were identified as potential ways that mpMRI can improve the prostate cancer patient pathway?1
      </span>
      <span class="guidance">Select all that apply</span>
      <span class="anwser">
        <label>
          <input v-model="a6" type="checkbox">
          Fewer biopsies, so that transrectal ultrasound and its associated morbidity can be avoided
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="b6" type="checkbox">
          Increased sensitivity of diagnosis in men with larger prostate size (for example owing to BPH)
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="c6" type="checkbox">
          Reduced reliance on less specific testing methods such as digital rectal examination and PSA, providing better certainty of diagnosis for patients
        </label>
      </span>
      <span class="anwser">
        <label>
          <input  v-model="d6" type="checkbox">
          Fewer unnecessary treatments, by avoiding the potential for over-diagnosis and over-treatment of prostate cancer
        </label>
      </span>
      <span class="anwser">
        <label>
          <input  v-model="e6" type="checkbox">
          Improved risk stratification, as a suspicious mpMRI can help target biopsies and give a better representation of the amount of cancer
        </label>
      </span>
      <span class="notes">
        BPH=benign prostatic hyperplasia; mpMRI=multiparametric magnetic resonance imaging; PSA=prostate-specific antigen.
        1. Prostate Cancer UK. Guidance for Clinical Commissioning Groups: Commissioning multi-parametric MRI before first biopsy for men with suspected prostate cancer. May 2017. Available from:
        <a href="https://prostatecanceruk.org/media/izilpgwc/ccg-checklist_may2017_webcopy.pdf">
          https://prostatecanceruk.org/media/izilpgwc/ccg-checklist_may2017_webcopy.pdf
        </a>
        [Last accessed: August 2022].
      </span>
      <hr />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModuleTwoQuizQ6',
    data() {
      return {
        a6: false,
        b6: false,
        c6: false,
        d6: false,
        e6: false,
      };
    },
    watch: {
      a6(value) { this.$store.commit('answerM2A6', value) },
      b6(value) { this.$store.commit('answerM2B6', value) },
      c6(value) { this.$store.commit('answerM2C6', value) },
      d6(value) { this.$store.commit('answerM2D6', value) },
      e6(value) { this.$store.commit('answerM2E6', value) },
    },
  };
  </script>
  