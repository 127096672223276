<template>
    <div>
      <span class="question">
        2. Which of the following correctly describes the structure of an Integrated Care System (ICS)?<sup>1,2</sup>
      </span>
      <span class="anwser">
        <label>
          <input v-model="a2" type="checkbox">
          The Integrated Care Board is tasked with commissioning and oversight of NHS services; the Integrated Care Partnership brings together a wider range of partners to develop a plan to address the needs of the population
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="b2" type="checkbox">
          The Integrated Care Service Provider commissions service providers meeting the NHS' standards and costs; the Integrated Care Quality Commission regulates these providers
        </label>
      </span>
      <span class="anwser">
        <label>
          <input v-model="c2" type="checkbox">
          The Integrated Care Body distributes funds from the ICS and is tasked with bringing equality to the different health needs of the population; the Integrated Care Partnership allows patients to directly influence the services commissioned
        </label>
      </span>
      <span class="notes">
        1. NHS England. What are integrated care systems? Available from:
        <a href="https://www.england.nhs.uk/integratedcare/what-is-integrated-care/">
          https://www.england.nhs.uk/integratedcare/what-is-integrated-care/
        </a>
        [Last accessed: August 2022];
        2. Kings Fund. Integrated care systems: how will they work under the Health and Care Act? May 2022. Available from:
        <a href="https://www.kingsfund.org.uk/audio-video/integrated-care-systems-health-and-care-act">
          https://www.kingsfund.org.uk/audio-video/integrated-care-systems-health-and-care-act
        </a>
        [Last accessed: August 2022].
      </span>
      <hr />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModuleTwoQuizQ2',
    data() {
      return {
        a2: false,
        b2: false,
        c2: false,
      };
    },
    watch: {
      a2(value) { this.$store.commit('answerM2A2', value) },
      b2(value) { this.$store.commit('answerM2B2', value) },
      c2(value) { this.$store.commit('answerM2C2', value) },
    },
  };
  </script>
  